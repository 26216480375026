import { Injectable } from "@angular/core";
import { MessageLevels } from "./message-levels.enum";
import { Message } from './message';

@Injectable()
export class MessageBoardService {
    private _messages: Message[];

    constructor() {
        this._messages = [];
    }

    get messages() {
        return this._messages;
    }

    postMessage(msg: string, level: MessageLevels = MessageLevels.INFO) {
        this._messages.push(new Message(msg, level));
    }

    removeMessage(msg: Message) {
        const index = this._messages.indexOf(msg);
        this._messages.splice(index, 1);
    }
}