<div class="container">
  <app-message-board></app-message-board>
</div>
<div class="container" id="app-content"><img class="img-responsive" src="/assets/imgs/logo.png" alt="logo procempa" />
  <div id="text-box">
    <p id="welcome">Bem-vindo à rede wireless<br/>da Prefeitura de Porto Alegre</p><br/>
    <p id="message">A utilização deste serviço é gratuita e<br/>vinculada à aceitação deste<br/><a (click)="toggleModal()">termo de responsabilidade</a></p>
  </div>
  <ng-container *ngIf="!showLoading">
    <button class="btn btn-primary" type="button" (click)="login()" [disabled]="!userEmail.length">Navegar</button>
    <div><span class="text-muted">*Ao clicar em navegar, atesto que li e estou de acordo com o termo de uso</span></div>
  </ng-container>
  <ng-container *ngIf="showLoading">
    <div class="loader"> </div>
  </ng-container>
</div>
<ng-container *ngIf="showModal">
  <bs-modal [title]="'Termo de Uso - Porto Alegre Livre'" [show-modal]="showModal" (onClose)="toggleModal()">
    <div id="use-terms" body="body">
      <h4>Descrição do serviço</h4>
      <p>O serviço público de acesso sem fio à internet, Porto Alegre Livre, é uma iniciativa da Secretaria Municipal de Direitos
        Humanos, com o apoio da Procempa que objetiva democratizar o acesso à internet nos espaços públicos da cidade.</p><br/>
      <h4>Termos e condições</h4>
      <p>Ao utilizar este serviço de internet, você reconhece e concorda expressamente que existem riscos significativos de
        segurança, privacidade e confidencialidade inerentes ao acesso ou transmissão de informações através da Internet,
        seja a conexão cabeada ou sem fio. Os problemas de segurança incluem, dentre outros: intercepção de dados, perda
        de dados e a introdução de vírus e outros programas que podem corromper ou danificar seu dispositivo.</p>
      <p>Em consonância, você concorda que o proprietário e/ou o provedor deste serviço NÃO é responsável por qualquer intercepção,
        transmissão, infecção por malware, perda de dados, corrupção de arquivos, hacking ou danos ao seu dispositivo ou
        a outros dispositivos resultantes da transmissão ou download de informações ou materiais através do serviço de internet
        fornecido.
      </p>
      <p>O uso desta rede sem fio está sujeito às restrições gerais descritas a seguir. Se comportamento anormal, ilegal ou
        não autorizado for detectado, incluindo o consumo excessivo de banda, o provedor de rede se reserva o direito de
        desconectar permanentemente o dispositivo malicioso da rede sem fio.</p>
      <p>Ao utilizar este serviço de acesso à internet, você atesta que as credenciais utilizadas para autenticação neste Portal
        são pessoais, autênticas e exclusivas, podendo ser estas utilizadas para sua identificação no caso de atividade ilegal
        ou fraudulenta.</p><br/>
      <h4>Exemplos de uso ilegal</h4>
      <p>Os exemplos seguintes são representativos e não incluem uma lista exaustiva de usos ilegais:</p>
      <ol type="1">
        <li>Spamming e invasão de privacidade - Envio de mensagens não solicitadas em lote e/ou comerciais através da Internet
          ou atividades que invadam a privacidade de outro.</li>
        <li>Violações dos direitos de propriedade intelectual - Envolver-se em qualquer atividade que infrinja ou aproprie indevidamente
          dos direitos de propriedade intelectual de outros, incluindo patentes, direitos autorais, marcas registradas, marcas
          de serviço, segredos comerciais ou qualquer outro direito de propriedade de terceiros.</li>
        <li>Acessar sem autorização computadores, contas, equipamentos ou redes pertencentes à outra parte, ou tentar penetrar/contornar
          medidas de segurança de outro sistema. Isso inclui qualquer atividade que possa ser usada como um precursor de
          uma tentativa de penetração no sistema, incluindo, mas não limitado a, varreduras de portas, varredura furtiva
          ou outra atividade de coleta de informações.</li>
        <li>Transferir tecnologia, software ou outros materiais, em violação às leis e regulamentos de exportação aplicáveis.</li>
        <li>Utilizar o Serviço de Internet em violação a qualquer lei e regulamento aplicáveis, incluindo, entre outros, publicidade,
          transmissão ou disponibilização de esquemas de ponzi, esquemas de pirâmide, cobrança fraudulenta de cartões de
          crédito, software de pirataria ou oferta fraudulenta para vender ou comprar produtos, itens ou serviços.</li>
        <li>Utilizar o Serviço de Internet para fazer ameaças a outrem.</li>
        <li>Distribuir material pornográfico para menores.</li>
        <li>Distribuir material de pedofilia.</li>
        <li>Distribuir conteúdo de cunho racista.</li>
      </ol><br/>
      <h4>Exemplos de uso inaceitáveis</h4>
      <p>Os exemplos seguintes são representativos e não incluem uma lista exaustiva de usos inaceitáveis do serviço de acesso
        à internet fornecido:</p>
      <ol type="1">
        <li>Publicar discursos ou materiais obscenos ou indecentes.</li>
        <li>Utilizar linguagem difamatória ou abusiva.</li>
        <li>Utilizar o serviço para transmitir, publicar, carregar ou, de outra forma, disponibilizar material ou linguagem difamatório,
          assediante, abusivo ou ameaçador que incentive danos corporais, destruição de propriedade ou assédio outro.</li>
        <li>Forjar ou deturpar cabeçalhos de mensagens seja no todo ou em parte, para mascarar o originador de mensagens.</li>
        <li>Facilitar a violação deste Termo de Uso.</li>
        <li>Distribuir vírus, cavalos de Tróia ou outros softwares com capacidade destrutiva.</li>
        <li>Utilizar o serviço de internet para realizar ataques de negação de serviço.</li>
        <li>Distribuir informações sobre a criação e envio de vírus, worms, cavalos de Tróia ou ataques de negação de serviço.
          Além disso, atividades que perturbam o uso ou interferem com a capacidade de outros para efetivamente usar qualquer
          rede, sistema, serviço ou equipamento conectado.</li>
        <li>Publicitar, transmitir ou disponibilizar qualquer produto, produto ou serviço de software concebido para violar este
          Termo de Uso.</li>
        <li>Vender, transferir ou alugar este serviço de conexão à internet a terceiros, diretamente ou como parte de um serviço
          ou produto criado para revenda.</li>
        <li>Procurar informações e/ou dados sensíveis, como senhas, pertencentes a outro usuário.</li>
        <li>Fazer cópias não autorizadas de software proprietário ou oferecer cópias não autorizadas de software proprietário
          para outros.</li>
        <li>Interceptar ou examinar o conteúdo de mensagens, arquivos ou comunicações em trânsito em qualquer rede de dados.</li>
      </ol>
    </div>
  </bs-modal>
</ng-container>
