import { MessageBoardService } from '../../services/messageBoard-service/messageBoard.service';
import { Component } from '@angular/core';
import { Message } from '../../services/messageBoard-service/message';

@Component({
  selector: 'app-message-board',
  templateUrl: './message-board.component.html',
  styleUrls: ['./message-board.component.scss']
})
export class MessageBoardComponent {

  messages: Message[];
  messageBoardProvider: MessageBoardService;

  constructor(messageBoardProvider: MessageBoardService) {
    this.messageBoardProvider = messageBoardProvider;
    this.messages = messageBoardProvider.messages;
  }

  removeMessage(message: Message):void{
    this.messageBoardProvider.removeMessage(message);
  }

}
