import { environment } from '../../environments/environment';
import { Observable, map } from 'rxjs';

import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';

@Injectable()
export class LoginLoggingService {
  private _api_endpoint: string;
  constructor(private _http: HttpClient) {
    this._api_endpoint = environment.LoginLoggingServiceApiEndpoint;
  }

  log(): Observable<any> {
    console.log(`${this._api_endpoint}/login`);
    return this._http.post<any>(`${this._api_endpoint}/login`, null)
  }

}
