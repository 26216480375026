import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MessageBoardService } from './common/services/messageBoard-service/messageBoard.service';
import { CookieService } from 'ngx-cookie-service';

import { LoginLoggingService } from "./services/login-logging.service";
import { LoginService } from './common/services/login-service/login.service';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { MessageBoardComponent } from './common/components/message-board/message-board.component';
import { BsModalComponent } from './common/components/bs-modal/bs-modal.component';
import { HttpClientModule } from '@angular/common/http';
import { environment } from 'src/environments/environment';


function initializeKeycloak(keycloak: KeycloakService) {
  return () =>
    keycloak.init({
      config: {
        "realm": environment.KeycloakConfig.realm,
        "url": environment.KeycloakConfig.url,
        "clientId": environment.KeycloakConfig.clientId
      },
      initOptions: {
        onLoad: 'login-required',
        silentCheckSsoRedirectUri:
          window.location.origin + '/assets/silent-check-sso.html'
      },
      shouldAddToken: (request) => {
        const { method, url } = request;

        const isGetRequest = 'GET' === method.toUpperCase();
        const acceptablePaths = ['/assets'];
        const isAcceptablePathMatch = acceptablePaths.some((path) =>
          url.includes(path)
        );
        return !(isGetRequest && isAcceptablePathMatch);
      }
    });
}

@NgModule({
  declarations: [
    AppComponent,
    MessageBoardComponent,
    BsModalComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    KeycloakAngularModule
  ],
  providers: [
    LoginLoggingService,
    KeycloakService,
    LoginService,
    MessageBoardService,
    CookieService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService]
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
