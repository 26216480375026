import { fadeInAnimation, fadeOutAnimation } from '../../animations/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'bs-modal',
  templateUrl: './bs-modal.component.html',
  styleUrls: ['./bs-modal.component.scss'],
  animations: [fadeInAnimation, fadeOutAnimation],
  // attach the fade in animation to the host (root) element of this component
  host: { '[@fadeInAnimation]': '', '[@fadeOutAnimation]': '' }
})
export class BsModalComponent implements OnInit {

  @Input('show-modal') showModal!: boolean;
  @Input('title') title!: string;
  @Input('cancel-label') cancelLabel!: string;
  @Input('positive-label') positiveLabel!: string;

  @Output('onClose') closeEmitter: EventEmitter<ModalResult> = new EventEmitter<ModalResult>();
  @Output('loaded') loadedEmitter: EventEmitter<BsModalComponent> = new EventEmitter<BsModalComponent>();
  @Output() positiveLabelAction = new EventEmitter();

  constructor() { }

  ngOnInit() {
    this.loadedEmitter.next(this);
  }

  show() {
    this.showModal = true;
  }

  hide() {
    this.showModal = false;
    this.closeEmitter.next({
      action: ModalAction.POSITIVE
    });
  }

  positiveAction() {
    this.positiveLabelAction.next(this);
    return false;
  }

  cancelAction() {
    this.showModal = false;
    this.closeEmitter.next({
      action: ModalAction.CANCEL
    });
    return false;
  }
}

export enum ModalAction { POSITIVE, CANCEL }

export interface ModalResult {
  action: ModalAction;
}
