import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MessageBoardService } from './common/services/messageBoard-service/messageBoard.service';
import { LoginLoggingService } from './services/login-logging.service';
import { LoginService } from './common/services/login-service/login.service';
import { CookieService } from 'ngx-cookie-service';

import { MessageLevels } from './common/services/messageBoard-service/message-levels.enum';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'app';
  redirect!: string;
  userEmail: string;
  showModal: boolean;
  showLoading: boolean;

  constructor(
    private _activedRoute: ActivatedRoute,
    private _messageBoardService: MessageBoardService,
    private _loginLoggingService: LoginLoggingService,
    private _loginService: LoginService,
    private _cookieService: CookieService
  ) {
      this.userEmail = '';
      this.showModal = false;
      this.showLoading = false;

      this._loginService.getUserInfo().then(info => {
          this.userEmail = info.email;
      }).catch(err => {
          this._messageBoardService.postMessage(err, MessageLevels.ERROR);
      });

      this._activedRoute.queryParams.subscribe(params => {
          this.redirect = params['redirect'];
      });
  }

  agreed(): boolean {
      return this._cookieService.get('agreed_with_terms') === 'true';
  }


  _redirect(): void {
      window.location.href = 'https://prefeitura.poa.br/'
  }

  login(): void {
    // Workaround Mozilla
    document.requestStorageAccess()
      .then((handle) => console.log("StorageAccess granted"),
      () => console.log("StorageAccess denied")
    );

    this._loginLoggingService.log().subscribe(data => {
        if (!this.agreed()) {
            let targetDate = new Date();
            targetDate.setDate(targetDate.getDate() + 365);
            this._cookieService.set('agreed_with_terms', 'true', { expires: targetDate });
            console.log(this._cookieService.getAll());
        }
        this.showLoading = true;
        setTimeout(() => {
            this._redirect();
        }, 3000);
    }, err => {
        this.showLoading = false;
        this._messageBoardService.postMessage(err, MessageLevels.ERROR);
    });
  }

  toggleModal(): void {
      this.showModal = !this.showModal;
  }

  public ngOnInit(): void {
      if (this.agreed()) {
          this.login();
      }
  }

}
