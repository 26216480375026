import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IKeycloakUserInfo } from "../../../interfaces/keycloak/IKeycloakUserInfo";
import { KeycloakService } from 'keycloak-angular';


@Injectable()
export class LoginService {

  constructor(private kc: KeycloakService, private _http: HttpClient) { }

  async authenticated(): Promise<boolean> {
    return await this.kc.isLoggedIn();
  }

  login() {
    this.kc.login();
  }

  logout() {
    this.kc.logout();
  }

  getUserInfo(): Promise<IKeycloakUserInfo> {

    let promise = new Promise<IKeycloakUserInfo>(
      (resolve: (token: any) => void, reject: (error: Error) => void) => {
        this.kc.loadUserProfile().then(token => {
          resolve(token)
        }, err => {
          reject(err);
        })
      }
    );
    return promise;
  }
}
