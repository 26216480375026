
<div class="modal-backdrop fade in" [style.display]="showModal ? 'block' : 'none'"></div>
<div class="modal" tabindex="-1" role="dialog" style="display: block;" [style.display]="showModal ? 'block' : 'none'">
  <div class="modal-dialog">     
    <div class="modal-content">
      <div class="modal-header">
        <button class="close" type="button" data-dismiss="modal" (click)="hide()">&times;</button>
        <h4 class="modal-title">{{title}}</h4>
      </div>
      <div class="modal-body">
        <ng-content select="[body]"></ng-content>
      </div>
      <div class="modal-footer">
        <ng-content select="[footer]"></ng-content>
      </div>
    </div>
  </div>
</div>